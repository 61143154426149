import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchUserProfile = (id) =>
  axios.get(`/api/profile/user/${id}`, optionsFunction());

export const fetchUser = () => axios.get(`/api/user`, optionsFunction());
export const deleteUser = () => axios.delete(`/api/user`, optionsFunction());

export const fetchUserById = (id) =>
  axios.get(`/api/user/${id}`, optionsFunction());

export const getUserObject = () => axios.get(`/api/user/`, optionsFunction());

export const fetchAvatars = () =>
  axios
    .get("/api/profile/avatar", optionsFunction())
    .then((res) => res.data.data.avatars)
    .catch((e) => console.log(e));

export const subscribeNewsletter = () =>
  axios
    .put("/api/profile/newsletter", {}, optionsFunction())
    .then((res) => res.data.status)
    .catch((e) => console.log(e));
export const unsubscribeNewsletter = () =>
  axios
    .delete("/api/profile/newsletter", optionsFunction())
    .then((res) => res.data.status)
    .catch((e) => console.log(e));

export const unsubscribeNewsletterByLink = (hash) =>
  axios.delete(`/api/email/unsubscribe/${hash}`, optionsFunction());

export const storeBirthday = (date) =>
  axios.post(
    "/api/profile/dateofbirth",
    {
      profile_dateofbirth: date,
    },
    optionsFunction()
  );

export const updateEmail = (newEmail, localeQuery) =>
  axios.post(
    `/api/user/email/change/request?locale=${localeQuery}`,
    {
      email: newEmail,
    },
    optionsFunction()
  );

export const confirmEmail = () =>
  axios.post("/api/user/email/confirm/request", {}, optionsFunction());

export const updateUsername = (newUsername) =>
  axios.post(
    "/api/profile/username",
    {
      profile_username: newUsername,
    },
    optionsFunction()
  );

export const selectNewAvatar = (key) =>
  axios.post(
    "/api/profile/avatar",
    {
      avatar: key,
    },
    optionsFunction()
  );

export const updatePassword = (oldPassword, newPassword) =>
  axios.post(
    `/api/profile/password`,
    { current_password: oldPassword, new_password: newPassword },
    optionsFunction()
  );

export const requestReset = (email) =>
  axios.post(`/api/password/forget`, { email: email }, optionsFunction());

export const resetPassword = (payload) =>
  axios.post(
    `/api/password/reset`,
    {
      email: payload.email,
      token: payload.token,
      password: payload.password,
      password_confirmation: payload.passwordConfirm,
    },
    optionsFunction()
  );

export const upgradeAccount = (payload) =>
  axios.post(
    `/api/profile/guest/upgrade`,
    {
      email: payload.email,
      password: payload.password,
    },
    optionsFunction()
  );

export const connectToFacebook = (token) =>
  axios.post("/api/login/facebook/update", { token: token }, optionsFunction());

export const redeemVoucher = (voucherCode) =>
  axios.post("/api/voucher/redeem", { code: voucherCode }, optionsFunction());

// settings
export const getUserNameHistory = () =>
  axios.get("/api/profile/usernames", optionsFunction());

export const acceptNewToS = (termsId, newsletterBool) => {
  return axios.post(
    `/api/terms/${termsId}`,
    {
      newsletter: newsletterBool,
    },
    optionsFunction()
  );
};
export const getTermsData = (termsId) => {
  return axios.get(`/api/terms/${termsId}`, optionsFunction());
};
